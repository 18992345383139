import React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'

import { Box, Flex, useTheme } from '@chakra-ui/react'

import { Menu, MenuLink } from '../ui'

import logo from '../images/logo-white.png'

function Header({ variantHeader, ...restProps }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box zIndex={2} position="absolute" width="100%">
      <Menu
        logo={
          <Link to="/">
            <Flex height={{ base: '35px', md: '50px' }} justifyContent={{ lg: 'center' }}>
              <img style={{ height: '100%', width: 'auto' }} src={logo} />
            </Flex>
          </Link>
        }
        margin="0 auto"
        maxWidth={theme.breakpoints.xl}
        {...restProps}
      >
        <MenuLink color="white" as={Link} to="/" fontWeight="bold">
          Home
        </MenuLink>
        <MenuLink color="white" as={Link} to="/rooms" fontWeight="bold">
          Habitaciones
        </MenuLink>
        <MenuLink color="white" as={Link} to="/weddings-and-retreats" fontWeight="bold">
          Bodas y Retiros
        </MenuLink>
        <MenuLink color="white" as={Link} to="/contact" fontWeight="bold">
          Contacto
        </MenuLink>
      </Menu>
    </Box>
  )
}

Header.propTypes = {
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Header.defaultProps = {
  variantHeader: 'solid',
}

export default Header
