import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'

import { Flex, Button, Stack } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Popup,
  Modal,
  H1,
  H2,
} from '../ui'

import Header from './header'

import { bookingModalState } from '../store'
import SEO from './seo'

import logoFooter from '../images/favico.png'
import { v4 } from 'uuid'
import { format } from 'date-fns'

const globalStyles = css`
  @font-face {
    font-family: Raleway;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: Raleway;
    font-style: bold;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: Raleway;
    font-style: black;
    font-weight: 900;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: Raleway, Arial, Helvetica, sans-serif !important;
    font-weight: 400;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    opacity: 0.85;
  }
`

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const isCoyote = process.env.GATSBY_HOTEL_VAR === 'casa-coyote'

  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {!disableHeader && <Header variantHeader={variantHeader} />}
      <Flex direction="column" minHeight="100vh">
        <Main>{children}</Main>
      </Flex>

      <Footer backgroundColor="gray.100">
        <FooterBody>
          <FooterColumn>
            <Link to="/">
              <Flex width={{ base: '50px' }}>
                <img src={logoFooter} />
              </Flex>
            </Link>
            {/* <Flex>
              <Button
                colorScheme="black"
                variant="outline"
                leftIcon={<MdLanguage />}
                onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
                _hover={{ background: 'transparent' }}
              >
                {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
              </Button>
            </Flex> */}
          </FooterColumn>
        </FooterBody>
        <FooterDivider backgroundColor="red.800" />
        <FooterBottom direction={{ base: 'column', md: 'row' }} justify="space-between">
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            © Cancun Family Tours. Derechos Reservados, 2021.
          </P>
        </FooterBottom>
      </Footer>

      {/* {!disableWhatsapp && (
        <WhatsappButton
          bottom="75px"
          href={`https://api.whatsapp.com/send?phone=5219992237524&text=Hola, quiero más información para reservar en Hacienda Poxilá...`}
        />
      )} */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
  variantHeader: 'transparent',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1860, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 680, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
